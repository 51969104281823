<!-- About.vue -->
<template>
  <h1>About Page</h1>
</template>

<script>
import { onMounted } from "vue";
import { ElMessage } from "element-plus";
export default {
  name: "AboutIndex",
  setup() {
    // 组件挂载时调用 fetchData
    onMounted(() => {
      ElMessage.error("请求");
    });

    // 返回响应式引用和函数，使其在模板中可用
    return {};
  },
};
</script>
