<template>
  <div class="body">
    <div class="container">
      <h1>欢迎使用原版官方ChatGPT</h1>
      <p>无限制地探索、学习与创造，立即开始您的AI旅程。</p>
      <p>无需担心账号与访问限制，我们提供免费共享账号供您体验。</p>
      <div class="bot">
        <a
          href="https://66.vue3.cc/auth0/encrypt-url?url=/1/ChatGPT.exe"
          class="button windows"
          download
        >
          <img src="../../assets/Windows.png" alt="Windows" />Windows下载
        </a>
        <a
          href="https://66.vue3.cc/auth0/encrypt-url?url=/1/ChatGPT.dmg"
          class="button mac"
          download
        >
          <img src="../../assets/macos.png" alt="MacOS" />Mac下载
        </a>
      </div>
    </div>
    <div class="footer">
      <text>此软件仅供个人使用，如有问题 &nbsp;</text>
      <a
        style="color: #105576; text-decoration: none"
        href="mailto:3547646@gmail.com"
      >
        请联系我们
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadPage",
  setup() {
    return {};
  },
};
</script>

<style scoped>
.body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}
h1 {
  color: #333333;
}
p {
  color: #666666;
}
.button {
  display: flex;
  align-items: center;
  margin-top: 50px;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.3s;
  width: 135px;
  justify-content: center;
}
.button img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.windows {
  background-color: #3ead33;
}
.mac {
  background-color: #000000;
  margin-left: 30px;
}
.button:hover {
  opacity: 0.85;
}
.footer {
  margin-top: auto;
  font-size: 12px;
  color: #777;
  width: 100%;
  position: fixed;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bot {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40px;
}
@media (max-width: 768px) {
  .container {
    text-align: center;
    padding: 20px;
  }
  .bot {
    flex-direction: column;
  }
  .mac,
  .windows {
    margin: 0;
    margin-top: 25px;
  }
}
@media (min-width: 768px) {
  .container {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
</style>
